import { useEffect, useState } from "react";
import Dispatcher from "./components/Dispatcher";
import { get, post, put } from "./lib/http/Http";
import { useForm } from "react-hook-form";

function CartCheck({ dataset }) {
  const [customerId, setCustomerId] = useState();
  const [defaultValues, setDefaultValues] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [metafields, setMetafields] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues,
  });
  const onSubmit = (_data) => {
    const data = {
      ..._data,
    };
    console.log(data);
  };

  const retrieveCustomer = async () => {
    const response = await get({
      path: `applications/shopify/${customerId}`,
    });
    setMetafields(response.data.metafields);
    setAddresses(response.data.customer.addresses);
  };

  useEffect(() => {
    customerId && retrieveCustomer();
  }, [customerId]);

  useEffect(() => {
    setCustomerId(window.__st?.cid);
  }, []);

  useEffect(() => {
    if (metafields) {
      const billingAddress = metafields.find(
        (metafield) => metafield.key === "indirizzo_fatturazione_di_default"
      );
      if (billingAddress) {
        setAddressId(billingAddress.value);
      } else {
      }
    }
  }, [metafields]);

  const submitBillingAddress = async (addressId) => {
    const response = await post({
      path: `applications/shopify/${customerId}/billing-address`,
      data: {
        data: {
          addressId,
        },
      },
    });
    console.log(response.data);
  };

  useEffect(() => {
    if (addressId) {
      submitBillingAddress(addressId);
    }
  }, [addressId]);

  if (
    customerId &&
    metafields &&
    !metafields?.find(
      (metafield) => metafield.key === "indirizzo_fatturazione_di_default"
    )
  ) {
    return (
      <>
        <div class="widget-modal">
          <div
            class="widget-modal__inner"
           
          >
          
            <p>
                Prima di continuare con il checkout abbiamo necessità che vengano aggiornati i vostri dati di fatturazione ed impostato almeno un indirizzo di fatturazione.
                
            </p>

            <a href="/pages/modifica-account" className="btn cart__checkout">
              Vai al tuo account
            </a>
          </div>
        </div>
      </>
    );
  }

  return <></>;
}

export default CartCheck;
