import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Addresses from "./Addresses";
import CartCheck from "./CartCheck";
const widgetDivs = document.querySelectorAll(".registration-widget");

widgetDivs.forEach((widgetDiv) => {
  const root = ReactDOM.createRoot(widgetDiv);
  const { template } = widgetDiv.dataset;
  console.log("widgetDiv.dataset",widgetDiv.dataset)
  root.render(
    <React.StrictMode>
      {template === "address" ? (
        <Addresses dataset={widgetDiv.dataset} />
      ) : (
        <>
          {template === "cart-check" ? (
            <CartCheck dataset={widgetDiv.dataset} />
          ) : (
            <App dataset={widgetDiv.dataset} />
          )}
        </>
      )}
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
