import { useEffect, useState } from "react";
import { get } from "../lib/http/Http";

export default function Dispatcher({
  dataset,
  setFormId,
  setFormName,
  dispatcherName,
  formId,
  customerId,
}) {
  const [forms, setForms] = useState([]); // [forms, setForms
  const retrieveForms = async () => {
    const response = await get({
      path: "forms",
    }).catch((error) => {
      console.log(error);
    });
    setForms(response.data.data);
  };

  useEffect(() => {
    console.log("retrieveForms");
    retrieveForms();
  }, [dataset]);

  useEffect(() => {
    if (forms.length > 0) {
      setFormId(forms[0].id);
      setFormName(forms[0].attributes.title);
    }
  }, [forms]);

  useEffect(() => {
    if (dispatcherName) {
      setTimeout(() => {
        setFormId(
          forms.find((form) => form.attributes.title === dispatcherName).id
        );
      }, 2000);
    }
  }, [dispatcherName]);

  return (
    <div className="cf-select-wrap" style={{
      
    }}>
      <label htmlFor="form_id">TIPOLOGIA CLIENTE *</label>
      <select
        style={{
          width: "100%",
        }}
        disabled={customerId}
        value={formId}
        onChange={(e) => {
          setFormId(e.target.value);
          setFormName(e.target.options[e.target.selectedIndex].text);
        }}
        name="form_id"
        id="form_id"
      >
        <option value="">Seleziona</option>
        {forms.map((form) => {
          return <option value={form.id}>{form.attributes.title}</option>;
        })}
      </select>
    </div>
  );
}
