import axios from 'axios';
const baseUrl = 'https://sir126.qdivision-shopify.com/api/';

const get = ({
	path,
	params,
	local,
	headers
}) => {
	const _baseUrl = local ? '' : baseUrl;
	return axios.get((_baseUrl + path), { headers })
}

const post = ({
	path,
	data
}) => {
	return axios.post((baseUrl + path), data)
}

const put = ({
	path,
	data
}) => {
	// console.log(baseUrl + path);
	// console.log(data)
	return axios.put((baseUrl + path), data)
}

const remove = ({
	path
}) => {
	return axios.delete((baseUrl + path))
}



export {
	get,
	post,
	put,
	remove
}